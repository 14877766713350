import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LinkedListChallenges from "../../../components/challenges/LinkedList/LinkedListChallenges";
export const _frontmatter = {
  "templateKey": "example",
  "type": "challenge",
  "title": "Linked List",
  "slug": "/portfolio/linkedlist",
  "author": "Gabriel Kirkley",
  "image": "./links.jpg",
  "using": "JavaScript",
  "source": "https://github.com/gdgkirkley/gabrielkirkley.ca/blob/master/src/components/challenges/LinkedList/LinkedList.js",
  "published": true,
  "date": "2020-07-25T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This is a sample problem using a Linked List.`}</p>
    <LinkedListChallenges mdxType="LinkedListChallenges" />
    <p>{`The duplicate removal algorithm is implemented with an O(n) solution using a
Map:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`class LinkedList {
 ...

  removeDuplicates() {
    let previouslySeen  = new Map();

    let iterations      = this.count;
    let current         = this.head;
    let prev            = this.head;

    for (let i = 0; i < iterations; i++) {
      if (previouslySeen.has(current.value)) {
        if (current.next) {
          prev.next = current.next;
        } else {
          prev.next = null;
        }

        this.decrementCount();
      } else {
        previouslySeen.set(current.value, current);
        prev = current;
      }

      current = current.next;
    }
  }

 ...
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      